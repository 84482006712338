@import 'styles/_variables';
@import 'styles/_mixins';

.typography {
    color: $color-font-base;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: pre-line;

    &.size {
        &-44 {
            font-size: 44px;
            line-height: 52px;
        }

        &-32 {
            font-size: 32px;
            line-height: 36px;
        }

        &-20 {
            font-size: 20px;
            line-height: 32px;
        }

        &-18 {
            font-size: 18px;
            line-height: 28px;
        }

        &-16 {
            font-size: 16px;
            line-height: 24px;
        }

        &-14 {
            font-size: 14px;
            line-height: 20px;
        }

        &-12 {
            font-size: 12px;
            line-height: 18px;
        }

        &-9 {
            font-size: 9px;
            line-height: 20px;
        }
    }

    &.color {
        &-green {
            color: $color-brand-green;
        }

        &-dark {
            color: $color-brand-dark;
        }

        &-light {
            color: $color-brand-light;
        }

        &-error {
            color: $color-danger;
        }

        &-info {
            color: $color-info;
        }

        &-warning {
            color: $color-warning;
        }

        &-success {
            color: $color-success;
        }

        &-neutral {
            &-0 {
                color: $neutral-0;
            }

            &-50 {
                color: $neutral-50;
            }

            &-100 {
                color: $neutral-100;
            }

            &-200 {
                color: $neutral-200;
            }

            &-300 {
                color: $neutral-300;
            }

            &-400 {
                color: $neutral-400;
            }

            &-500 {
                color: $neutral-500;
            }

            &-600 {
                color: $neutral-600;
            }

            &-700 {
                color: $neutral-700;
            }

            &-800 {
                color: $neutral-800;
            }

            &-900 {
                color: $neutral-900;
            }
        }

        &-davy-grey {
            &-50 {
                color: $davy-grey-50;
            }

            &-100 {
                color: $davy-grey-100;
            }

            &-200 {
                color: $davy-grey-200;
            }

            &-300 {
                color: $davy-grey-300;
            }

            &-400 {
                color: $davy-grey-400;
            }

            &-500 {
                color: $davy-grey-500;
            }

            &-600 {
                color: $davy-grey-600;
            }

            &-700 {
                color: $davy-grey-700;
            }

            &-800 {
                color: $davy-grey-800;
            }

            &-900 {
                color: $davy-grey-900;
            }
        }

        &-stone {
            &-50 {
                color: $stone-50;
            }

            &-100 {
                color: $stone-100;
            }

            &-200 {
                color: $stone-200;
            }

            &-300 {
                color: $stone-300;
            }

            &-400 {
                color: $stone-400;
            }

            &-500 {
                color: $stone-500;
            }

            &-600 {
                color: $stone-600;
            }

            &-700 {
                color: $stone-700;
            }

            &-800 {
                color: $stone-800;
            }

            &-900 {
                color: $stone-900;
            }
        }
    }

    &.Mulish {
        &-light {
            font-family: $font-family-Mulish-light;
        }

        &-light-italic {
            font-family: $font-family-Mulish-light-italic;
        }

        &-extra-light {
            font-family: font-family-Mulish-extra-light;
        }

        &-extra-light-italic {
            font-family: $font-family-Mulish-extra-light-italic;
        }


        &-regular {
            font-family: $font-family-Mulish-regular;
        }

        &-italic {
            font-family: $font-family-Mulish-italic;
        }

        &-semi-bold {
            font-family: $font-family-Mulish-semi-bold;
        }

        &-semi-bold-italic {
            font-family: $font-family-Mulish-semi-bold-italic;
        }

        &-bold {
            font-family: $font-family-Mulish-bold;
        }

        &-bold-italic {
            font-family: $font-family-Mulish-bold-italic;
        }

        &-black {
            font-family: $font-family-Mulish-black;
        }

        &-black-italic {
            font-family: $font-family-Mulish-black-italic;
        }

        // &-extra-bold {
        //     font-weight: 800;
        // }

        // &-black {
        //     font-weight: 900;
        // }
    }

    &.not-selectable {
        user-select: none;
    }
}
