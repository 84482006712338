@import '_variables';

#root {
    height: 100%;
}

#outer-container {
    min-height: 100%;
    background-color: $neutral-0;
    // overflow: hidden;
}

#page-wrap {
    position: relative;
    z-index: 0;
    contain: layout;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5rem 1.75rem;
    height: 100%;
}

.safari-fix #page-wrap {
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

.loading-container {
    margin-top: $spacing-double;
}

.no-transition,
.no-transition * {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
}

.top-spacing {
    margin-top: $spacing;
}

.top-spacing-double {
    margin-top: $spacing-double;
}

.top-spacing-third {
    margin-top: $spacing-third;
}

.top-spacing-half {
    margin-top: $spacing-half;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-spaced {
    display: flex;
    justify-content: space-between;
}

.modal-content {
    border: none;
}

.Toastify__toast {
    border-radius: $border-radius-large;
    color: $neutral-50 !important;
    margin-bottom: 1rem !important;

    @include screen-xs {
        margin: 1rem !important;
    }

    &--error {
        background-color: $color-danger !important;
    }

    &--success {
        background-color: $stone-500 !important;
    }

    &-body {
        div:last-child {
            font-size: 12px;
            line-height: 16px;
            font-family: $font-family-Mulish-regular;
        }
    }

    &-icon {
        width: auto;
    }
}

.radius {
    &-0 {
        border-radius: $border-radius-base;
    }

    &-4 {
        border-radius: $border-radius-small;
    }

    &-6 {
        border-radius: $border-radius-medium;
    }

    &-9 {
        border-radius: $border-radius-large;
    }

    &-10 {
        border-radius: $border-radius-xl;
    }

    &-12 {
        border-radius: $border-radius-xxl;
    }
}

.cursor-pointer:hover {
    cursor: pointer;
}

.img-gallery-modal {
    .image-gallery-content.fullscreen{
        .image-grid__gallery-item{
            img{
                width: 100%;
                max-height: 100%;
                height: calc(100vh - 100px);
                object-fit: contain;
            }
        }
    }
}

.img-gallery-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.__cbio_ignored.cbio_session_controls{
    transform: none !important;
    left: inherit !important;
    right: 20px;
}

.tooltip-inner{
    max-width: 250px;
}

.support-container {
    @include screen-min-lg{
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0px 2px 15px -3px rgba(0,0,0,0.1);
    }
}