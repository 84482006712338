
@import 'styles/_variables';
@import 'styles/_mixins';

.flash-notification {
    position: fixed;
    top: 30px;
    right: 200px;
    transition: all 200ms ease-out;
    transform: translate(100%, 0);
    will-change: transform;
    display: none;
    min-height: 44px;
    z-index: 1500;
    width: 150px;

    &.is-visible {
        transform: translate(0, 0);
        display: inherit;
    }

    &__alert {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    }
}
